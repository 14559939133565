import Deck from "../deck.png";
import Cover from "../cover.jpg";

const Song = ({ currentSong, isPlaying }) => {
  return (
    <div className="song">
      <div className="song__data">
        <p>
          <i>"{currentSong.title}"</i>
        </p>

        <p>
          {currentSong.bands.map((band) => {
            return `${band.name} `;
          })}
        </p>
      </div>

      <div className="song__img">
        <img className="song__img--deck" src={Deck} alt="turntable" />
        <img
          className={`song__img--cover ${isPlaying ? "rotate" : ""}`}
          src={Cover}
          alt="cover"
        />
      </div>
    </div>
  );
};

export default Song;
