export const musicData = [
  {
    id: "15911",
    title: "Bellis",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 187,
    size: 4500793,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKPlrw1B8XmDDravfGYoyFAvP.mp3",
  },
  {
    id: "15914",
    title: "Iris",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 197,
    size: 4740911,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKRBqZorHPOPF5h9DMkRE8wix.mp3",
  },
  {
    id: "15913",
    title: "Dandelion",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 123,
    size: 2964793,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKQqFSNoRyBHpgR008qHM7yv2.mp3",
  },
  {
    id: "15915",
    title: "Rose",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 155,
    size: 3732793,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKRSjzFbXSwe3wqwBwureZf0g.mp3",
  },
  {
    id: "15916",
    title: "Tulip",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 198,
    size: 4764734,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKRjuSSytlQDnPOWsT5ccZatd.mp3",
  },
  {
    id: "15917",
    title: "Verbena",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 198,
    size: 4764734,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKS2lndU2Db1g1OMfdEE9IxOT.mp3",
  },
  {
    id: "15895",
    title: "Cloud in cloud",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 254,
    size: 6096979,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKHyAXtK379spghmWghz8Nzn4.mp3",
  },
  {
    id: "15896",
    title: "Moonset",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 149,
    size: 3576685,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKLUIHOqq3LVtGi0mbxeVdySj.mp3",
  },
  {
    id: "15902",
    title: "Waves of my river",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 144,
    size: 3456940,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKMsrtyHLGN5DWVxF84Q5RH39.mp3",
  },
  {
    id: "15903",
    title: "Butterfly in the field",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 226,
    size: 5424901,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKNDd2foZyIy9skWKeu18oR92.mp3",
  },
  {
    id: "15905",
    title: "Forgotten books",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 201,
    size: 4824920,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKNeUcQo3GMjRkDfwog3fqjYW.mp3",
  },
  {
    id: "15908",
    title: "Window and woman",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 205,
    size: 4932754,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKOX8twd3y0tvlEFzcfEDN8zh.mp3",
  },
  {
    id: "15918",
    title: "Air Mood",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 142,
    size: 3421204,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKSbc4giheBNg7IJ3BqQqP4Eb.mp3",
  },
  {
    id: "15922",
    title: "Forest Stories",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 156,
    size: 3744705,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKTy0DlKqMarNSoSORzo6OWP6.mp3",
  },
  {
    id: "15923",
    title: "Yesterdays Memories",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 184,
    size: 4428695,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKUAYdPaLFo7ckqx8MFtVO5fJ.mp3",
  },
  {
    id: "15924",
    title: "Mirror",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 175,
    size: 4224940,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKUKTcdSu78NfvnRveUFlRhMu.mp3",
  },
  {
    id: "15927",
    title: "Wristwatch",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 155,
    size: 3744705,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKVZUJxD7ZeloU5ujPd6RimIa.mp3",
  },
  {
    id: "15934",
    title: "Midnight Dawn",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 195,
    size: 4680724,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKXGM95tm1MfcY4BG6PSWImOD.mp3",
  },
  {
    id: "15936",
    title: "Advertising Moon",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 165,
    size: 3960999,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKXbjj6EiEAQKv9p6wxFFpjxR.mp3",
  },
  {
    id: "15942",
    title: "Green lakes",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 169,
    size: 4068832,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKa5017JjhRohL8Ep25SGFnmZ.mp3",
  },
  {
    id: "15943",
    title: "Old friend",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 122,
    size: 2952881,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKaB6Nx59kX16x1FVHfqvjCKl.mp3",
  },
  {
    id: "15946",
    title: "Childhood dream",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 149,
    size: 3601136,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKbpExA4SgDhK6RZhubeMYpaN.mp3",
  },
  {
    id: "15949",
    title: "Rain under the rainbow",
    bands: [
      {
        id: "5899",
        name: "From Sam",
      },
    ],
    duration: 103,
    size: 2485185,
    url: "https://media-cdn.aitu.io/am-mp3/2VQKcrSBVhOmF1mmVa7OjJNg6WG.mp3",
  },
];
