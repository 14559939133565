import { useEffect, useState } from "react";
import aituBridge from "@btsd/aitu-bridge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompactDisc } from "@fortawesome/free-solid-svg-icons";
import logo from "../logo.svg";

const Nav = ({ libraryStatus, setLibraryStatus }) => {
  useEffect(() => {
    if (aituBridge.isSupported()) {
      getMe();
    }
  }, []);

  const [name, setName] = useState("<username>");

  async function getMe() {
    try {
      const data = await aituBridge.getMe();
      setName(data.name);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <nav>
      <div className="logo">
        <img src={logo} alt="Aitu Business" />
      </div>
      <span>Hi, {name}</span>
      <button onClick={() => setLibraryStatus(!libraryStatus)}>
        <span>PLAYLIST </span>
        <FontAwesomeIcon icon={faCompactDisc} size="2x" />
      </button>
    </nav>
  );
};

export default Nav;
